import { Directive, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { hasSomeChanges } from '@cawita/core-front';
import { NzDatePickerComponent, PresetRanges } from 'ng-zorro-antd/date-picker';

@Directive({
  selector: 'nz-range-picker',
  standalone: true,
})
export class DatePickerRangeHandlerDirective {

  private _registered: DatePickerRangeDirective[] = [];

  constructor(private picker: NzDatePickerComponent) { }

  public refresh() {
    this.picker.nzRanges = this._registered.reduce((map, range) => {
      map[range.label] = range.range;
      return map;
    }, {});
  }

  public register(range: DatePickerRangeDirective) {
    this._registered = [...this._registered, range];
    this.refresh();
  }

  public unregister(range: DatePickerRangeDirective) {
    this._registered = this._registered.filter(r => r !== range);
    this.refresh();
  }

}


@Directive({
  selector: 'cwt-date-picker-range',
  standalone: true,
})
export class DatePickerRangeDirective implements OnInit, OnChanges, OnDestroy {

  @Input() label: string;
  @Input() range: PresetRanges[string];

  constructor(@Optional() private rangeHandler?: DatePickerRangeHandlerDirective) { }

  ngOnInit(): void {
    this.rangeHandler?.register(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['label', 'range'])) this.rangeHandler?.refresh();
  }

  ngOnDestroy(): void {
    this.rangeHandler?.unregister(this);
  }
}
