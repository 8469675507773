import { User } from "../auth/user.model";

export type StatusDateData<Meta = {}> = {
    date: Date;
    user: User;
} & Meta;

export type StatusDates<T extends string | number, Meta = {}> = {
    [key in T]: StatusDateData<Meta>;
}

export class StatusDatesProxy {
    constructor(data: StatusDates<any, any>) {
        return Object.keys(data).reduce((map, status) => {
            map[status] = {
                ...data[status],
                date: new Date(data[status].date),
                user: new User(data[status].user),
            };

            return map;
        }, {});
    }
}