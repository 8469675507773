import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CwtMediaModule } from '@cawita/core-front/asset';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { MediaAvatarAddonComponent } from './media-avatar-addon.component';
import { MediaLoadingAddonComponent } from './media-loading-addon.component';
import { MediaIconAddonComponent } from './media-icon-addon.component';
import { SharedCommonModule } from '@shared/modules';


@NgModule({
  declarations: [
    MediaAvatarAddonComponent,
    MediaLoadingAddonComponent,
    MediaIconAddonComponent
  ],
  imports: [
    CommonModule,
    SharedCommonModule,
    NzSkeletonModule,
    CwtMediaModule
  ],
  exports: [
    MediaAvatarAddonComponent,
    MediaLoadingAddonComponent,
    MediaIconAddonComponent,
    CwtMediaModule
  ]
})
export class SharedMediaModule { }
