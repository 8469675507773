import { Template } from "../../models";

export type MailRecipient<D> = {
    origin: string;
    email: string;
    label: string;
    data: D;
}

export type MailParams = {
};

export type MailPreviewOptions = {
    template: Template;
    params?: MailParams;
}

export type MailSendOptions = MailPreviewOptions & {
    emails: string[];
    subject: string;
    content: string;
}

export enum MailAction {
}