import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Address, Sizes, ClientStat } from "../common";

export enum ClientTitle {
    Mr = 'mr',
    Ms = 'ms'
}

@CwtModel({ name: 'client' })
@CwtCrudContract({ path: 'client', searchMapper: (search) => ({ code: { regex: `^${search}$`, options: 'i' } })})
export class Client extends CwtApiEntity {
    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() title: ClientTitle;
    @CwtProp() email: string;
    @CwtProp() phone1: string;
    @CwtProp() phone2: string;
    @CwtProp() clientId: string;
    @CwtProp() code: string;
    @CwtProp() seekedArticles: string;
    @CwtProp() notes: string;
    @CwtProp() wishCard: boolean;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp({ ref: Sizes }) sizes: Sizes;
    @CwtProp({ ref: ClientStat }) stats: ClientStat;
}