import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Client } from "../client";

@CwtModel({ name: 'payment' })
@CwtCrudContract({ path: 'payment' })
export class Payment extends CwtApiEntity {
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp() clientName: string;
    @CwtProp() paymentId: string;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() amount: number;
    @CwtProp() bankCheck: number;
    @CwtProp() bankCard: number;
    @CwtProp() cash: number;
    @CwtProp() credit: number;
    @CwtProp() creditor: string;
    @CwtProp() notes: string;
}