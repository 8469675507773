import { Directive, OnInit, Optional, Self } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

@Directive({
  selector: 'nz-range-picker,nz-date-picker',
  standalone: true,
})
export class DatePickerFormatDirective implements OnInit {

  constructor(
    @Self() @Optional() private datePicker?: NzDatePickerComponent
  ) { }

  ngOnInit(): void {
    if (this.datePicker) {
      this.datePicker.nzFormat = 'dd/MM/yyyy';
      this.datePicker.updateInputValue();
    }
  }
}