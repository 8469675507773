import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textColor',
  standalone: true,
})
export class TextColorPipe implements PipeTransform {

  transform(text: string, l = 50): string {
    let hash = 0;
    if (text?.length) {
      for (let i = 0; i < text.length; i += 1) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
    }

    return `hsl(${(hash % 360)}, 60%, ${l}%)`;
  }

}
