import { Pipe, PipeTransform } from '@angular/core';
import { ratio } from '@hints/utils/math';

@Pipe({
  name: 'ratio',
  standalone: true,
})
export class RatioPipe implements PipeTransform {
  constructor() { }
  transform(a: number, b: number, defaultRatio: number = 0) {
    return ratio(a, b, defaultRatio);
  }

}
