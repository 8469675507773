import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideNgxMask } from 'ngx-mask';
import { AppModule } from './app/pages/app.module';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    provideNgxMask()
  ]
}).catch(err => console.error(err));
