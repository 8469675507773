import { HttpContext } from '@angular/common/http';
import { Directive, HostListener, Input } from '@angular/core';
import { CwtStopPropagationDirective, isset } from '@cawita/core-front';
import { CwtApiFlags, CwtBlobApiService } from '@cawita/core-front/api';
import { CwtAssetService } from '@cawita/core-front/asset';
import { isEmpty } from '@hints/utils/data';
import { UploadFile } from '@shared/models';

@Directive({
    selector: '[cwtDownloadUploadFile]',
    hostDirectives: [CwtStopPropagationDirective],
    standalone: true,
})
export class DownloadUploadFileDirective {

    @Input('cwtDownloadUploadFile') file?: UploadFile | null;

    constructor(
        private blob: CwtBlobApiService,
        private asset: CwtAssetService
    ) { }

    @HostListener('click')
    public onClick() {
        if (!isset(this.file)) return;
        const path = this.asset.getAssetUrl(this.file);
        if (isEmpty(path)) return;
        this.blob.get(path, {}, {
            context: new HttpContext().set(CwtApiFlags.DownloadResponse, this.file?.originalName ?? this.file?.name)
        }).subscribe();
    }
}