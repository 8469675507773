import { CwtApiEntity, CwtProp, CwtModel, CwtSubCrudContract, CwtCrudContract } from '@cawita/core-front/api';
import { User } from './user.model';


export type UserAgent = {
    ua: string;
    browser: { name?: string, version?: string, major?: string };
    engine: { name?: string, version?: string };
    os: { name?: string, version?: string };
    device: { model?: string, type?: string, vendor?: string };
    cpu: { architecture?: string };
}

@CwtModel({ name: 'user-session' })
@CwtCrudContract({ path: 'user/:userId/user-session' })
@CwtSubCrudContract({ parent: User, parentPath: 'user', subPath: 'user-session'})
export class UserSession extends CwtApiEntity {
    @CwtProp({ ref: Date }) expirationDate: Date;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: User }) user: User;
    @CwtProp() ip: string;
    @CwtProp() userAgent: UserAgent;
}