import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty, isset } from '@hints/utils/data';
import { isFunction } from '@hints/utils/types';

@Pipe({
    name: 'sort',
    standalone: true,
})
export class SortPipe implements PipeTransform {
    transform<T, F extends (itemA: T, itemB: T) => number>(array: T[], sortFn: F): T[] {
        if (!isset(array) || isEmpty(array)) return [];
        if (!isset(sortFn) || !isFunction(sortFn)) return [];
        const match: T[] = [...array];
        return match.sort(sortFn);
    }
}