import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";

@CwtModel({ name: 'stats' })
@CwtCrudContract({ path: 'stats' })
export class Stats extends CwtApiEntity {
    @CwtProp() clientsNb: number;
    @CwtProp() articlesNb: number;
    @CwtProp() sold: number;
    @CwtProp() paid: number;
    @CwtProp() bought: number;
}