import { CwtProp, CwtSchema } from "@cawita/core-front/api";

@CwtSchema()
export class ClientStat {
    @CwtProp() totalCost: number;
    @CwtProp() totalPaid: number;
    @CwtProp() totalSold: number;
    @CwtProp() totalCredit: number;
    @CwtProp() totalPressing: number;
    @CwtProp() nbTotalArticles: number;
    @CwtProp() nbInStockArticles: number;
}