import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name',
  standalone: true,
})
export class NamePipe implements PipeTransform {
  transform(user: { firstName?: string, lastName?: string }) {
    return user?.firstName + ' ' + user?.lastName;
  }
}
