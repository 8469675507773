import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Client } from "../client";
import { Brand, Creditor, PaidBy, Sizes } from "../common";

@CwtModel({ name: 'article' })
@CwtCrudContract({ path: 'article', searchMapper: (search) => ({
    articleId: { regexp: `^${search}`, options: 'i' }})
})
export class Article extends CwtApiEntity {
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp({ ref: Creditor }) creditor: Creditor;
    @CwtProp({ ref: Brand }) brand: Brand;
    @CwtProp({ ref: PaidBy }) paidBy: PaidBy;
    @CwtProp() articleId: string;
    @CwtProp() description: string;
    @CwtProp() isInStock: boolean;
    @CwtProp() cost: number;
    @CwtProp() price: number;
    @CwtProp() pressing: number;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: Date }) sellingDate: Date;
    @CwtProp({ ref: Sizes }) sizes: Sizes;
    @CwtProp() miscellaneous: string;
}